@import "palettes";
@import "theme";
@import "mixins";

.figure {
  @include flex-center;
  flex-direction: column;
  margin: 40px 0;
  @include trim-v-margins;

  .figure_image {
    max-width: 100%;
    max-height: 100%;
    // box-shadow: $shadow;
  }

  .figure_caption {
    margin-top: 10px;
    margin-bottom: 0;
    font-style: italic;
    text-align: center;
  }
}
